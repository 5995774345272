import { SidebarSection, CompanyBadgeCard } from '@paddl/storybook';
import { MembersDirectoryModal, useResource } from '@paddl/utils-react';
import {
  shape, string
} from 'prop-types';
import React, { useMemo, useState } from 'react';
import { ProfileTopListByXp } from '../../components/ProfileTopListByXp';

export const WorkspaceSidebar = ({
  organisationLogo,
  organisationName,
  organisationId
}) => {
  const [isProfileDirectoryOpen, setIsProfileDirectoryOpen] = useState(false);

  const { data: organisationProfiles } = useResource(`${process.env.API_PREFIX_V2}/at-work/profiles/organisation/${organisationId}/by-xp`);

  const numberOfProfiles = useMemo(() => {
    if (!organisationProfiles) return 0;

    return organisationProfiles.profiles.length;
  }, [organisationProfiles]);

  const totalXp = useMemo(() => {
    if (!organisationProfiles) return 0;

    return organisationProfiles.profiles
      .map(({ xp }) => xp)
      .reduce((accumulativeSum, sum) => accumulativeSum + sum, 0);
  }, [organisationProfiles]);

  return (
      <div>
        <SidebarSection>
      <CompanyBadgeCard
        logo={organisationLogo}
        name={organisationName || ''}
        numberOfProfiles={numberOfProfiles}
        totalXp={totalXp}
        membersLinkOnClick={() => setIsProfileDirectoryOpen(true)}
      />
        </SidebarSection>
      <SidebarSection>
        <ProfileTopListByXp organisationId={organisationId} />

        {isProfileDirectoryOpen && <MembersDirectoryModal
          isModalOpen={isProfileDirectoryOpen}
          organisationId={organisationId}
          onClose={() => setIsProfileDirectoryOpen(false)}
        />}
      </SidebarSection>
      </div>
  );
};

WorkspaceSidebar.defaultProps = {
  organisationLogo: null
};

WorkspaceSidebar.propTypes = {
  organisationLogo: shape({
    mime: string,
    base64: string
  }),
  organisationName: string.isRequired,
  organisationId: string.isRequired
};
