import React from 'react';
import { bool, number, func } from 'prop-types';
import { useResource, MembersDirectoryModal_NEON } from '@paddl/utils-react';

export const WorkspaceDirectoryModal = ({
  isModalOpen,
  workspaceId,
  onClose
}) => {
  const { data, isLoading } = useResource(`${process.env.AT_WORK_API}/v2/workspace/${workspaceId}/directory`, !isModalOpen);
  const title = 'Workspace Members';
  const subtitle = 'Browse all members from this workspace.';

  return <MembersDirectoryModal_NEON
    isModalOpen={isModalOpen}
    onClose={onClose}
    members={data?.workspaceDirectory}
    isLoading={isLoading}
    title={title}
    subtitle={subtitle}
  />;
};

WorkspaceDirectoryModal.propTypes = {
  isModalOpen: bool,
  workspaceId: number.isRequired,
  onClose: func
};

WorkspaceDirectoryModal.defaultProps = {
  isModalOpen: false,
  onClose: () => {}
};
