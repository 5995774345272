import { ModularPage } from '@paddl/storybook';
import { isToggleEnabled } from '@paddl/utils-js';
import { MemberAppNavigation_Populated, MemberAppNavigation_Populated_NEON } from '@paddl/utils-react';
import React from 'react';

export const NotFound = () => {
  return <ModularPage
    pageContent={null}
    navigationContent={isToggleEnabled('neon')
      ? <MemberAppNavigation_Populated_NEON currentPage="workspace" />
      : <MemberAppNavigation_Populated currentPage="workspace" />}
  />;
};
