/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useCallback, useState } from 'react';
import {
  shape,
  func,
  bool,
  string,
  oneOfType,
  arrayOf
} from 'prop-types';
import { compose } from 'recompose';
import {
  hardRedirect, trackEvent, getDecodedUserSessionCookie, isToggleEnabled
} from '@paddl/utils-js';
import {
  Metadata,
  withDataSubmission,
  withDataFetching,
  withWorkspacesOrganisations,
  withHandleWorkspacesStatus,
  MemberAppNavigation_Populated,
  MemberAppNavigation_Populated_NEON
} from '@paddl/utils-react';
import MuiAvatar from '@mui/material/Avatar';
import {
  Button,
  Avatar,
  AvatarGroup,
  defaultAvatar,
  InnerContentWrapper,
  MainContentWrapper,
  Colors,
  H1,
  H2,
  PageColumn,
  Column,
  ModularPage
} from '@paddl/storybook';
import styled from '@emotion/styled';
import { GatewayContent_NEON } from './GatewayContent_NEON';

const {
  new_grey
} = Colors;

export const CompanyNameHeadLine = styled(H2)`
  color: ${new_grey};
`;

export const CtaWrapper = styled.div`
  margin: 32px 0;
`;

export const GatewayComp = ({
  match: {
    params: { organisationId }
  },
  withHandleWorkspacesStatus: {
    fetch: getWorkspaceStatus,
    response: workspaceStatus
  },
  getOrganisation: {
    fetchWithIds,
    response: organisationResponse
  },
  getProfilesForOrganisation: {
    fetch: fetchProfilesForOrganisation,
    response: {
      profiles
    }
  },
  activateOrganisation: {
    submit,
    isLoading,
    isSuccess,
    response
  },
  sideEffect
}) => {
  const decodedCookie = getDecodedUserSessionCookie();
  const [currentOrganisation, setCurrentOrganisation] = useState(undefined);

  useEffect(() => {
    if (window?.analytics) {
      window.analytics.page(
        'Workspace App',
        'Gateway',
        {
          workspaceId: organisationId
        });
    }
  }, [organisationId]);

  useEffect(() => {
    getWorkspaceStatus();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchProfilesForOrganisation(
      `${process.env.API_PREFIX_V2}/at-work/profiles/organisation/${organisationId}/by-xp`
    );

    fetchWithIds([organisationId]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { organisations } = workspaceStatus;
    const numberOfOrganisations = organisations?.length;

    const foundOrganisation =  organisations?.find(
      ({ id }) => id.toString() === organisationId
    );

    setCurrentOrganisation(foundOrganisation);

    if (numberOfOrganisations === 0 || (numberOfOrganisations > 0  && !foundOrganisation)) {
      hardRedirect('experiences');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceStatus]);

  useEffect(() => {
    if (isSuccess && response) {
      hardRedirect('workspaceById', { id: organisationId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, response]);

  const othersProfiles = useCallback(
    () => profiles?.filter(({ profileId }) => decodedCookie?.profile_id !== profileId),
    [profiles, decodedCookie]
  )();

  const { name: organisationName = '', logo: organisationLogo = undefined } =  organisationResponse?.[0] || {};

  const joinWorkSpace = () => {
    trackEvent('Joined Workspace', { organisationId });

    submit(`${process.env.API_PREFIX_V2}/at-work/profiles/activate`, {
      organisationId
    });

    sideEffect.submit(`${process.env.ORGANISATION_ADMIN_API}/workspace/${organisationId}/activate`, {
      organisationId
    }, 'put');
  };

  return currentOrganisation !== undefined ? (
    <MainContentWrapper>
      <Metadata title="Join Workspace | Paddl" />
      <InnerContentWrapper>
        <PageColumn>
          <Column style={{ gap: '24px', textAlign: 'center', alignItems: 'center' }}>
          <Avatar
            imageAlt={`${organisationName} logo`}
            base64Image={organisationLogo}
            name={organisationName}
            variant="rounded"
            size="large"
            hasElevation
          />
          <CompanyNameHeadLine>{organisationName}</CompanyNameHeadLine>
          </Column>
          <Column style={{  textAlign: 'center', alignItems: 'center' }}>
          <H1>Grow your innovation, tech and leadership skills.</H1>
          <p>
            {organisationName} is dedicated to supporting your personal growth,
            helping you contribute to their mission and building your skills for
            your own professional journey.
          </p>
          <p>
            Workplace Essentials delivers actionable upskilling Sessions focusing on
            innovation, tech savviness, leadership and communication to empower
            you in the ever-changing world of work.
          </p>
          <CtaWrapper>
            <Button
              variant="contained"
              data-test-id="join-workspace"
              size="large"
              onClick={() => joinWorkSpace()}
            >
              ✨ Join Workspace
            </Button>
          </CtaWrapper>
          {othersProfiles?.length > 0 && (
            <AvatarGroup max={5}>
              {othersProfiles.map(({ avatarUrl, fullName, profileId }) => (
                <MuiAvatar
                  className="profile-avatar"
                  src={avatarUrl || defaultAvatar}
                  alt={fullName}
                  data-testid={`avatar-${profileId}`}
                  key={profileId}
                />
              ))}
            </AvatarGroup>
          )}
          {othersProfiles?.length >= 3 && (
            <p data-testid="profiles-intro-text">
              {othersProfiles[0].fullName}, {othersProfiles[1].fullName} and{' '}
              {othersProfiles?.length - 2}{' '}
              {othersProfiles?.length - 2 > 1 ? 'others' : 'other'}.
            </p>
          )}
          </Column>
        </PageColumn>
      </InnerContentWrapper>
    </MainContentWrapper>
  ) : null;
};

GatewayComp.propTypes = {
  match: shape({
    params: shape({
      organisationId: string.isRequired
    }).isRequired
  }).isRequired,
  withHandleWorkspacesStatus: shape({
    fetch: func.isRequired,
    response: shape({}).isRequired,
    isSuccess: bool.isRequired
  }).isRequired,
  getOrganisation: shape({
    fetchWithIds: func.isRequired,
    response: arrayOf(shape({})).isRequired,
    isSuccess: bool.isRequired
  }).isRequired,
  activateOrganisation: shape({
    submit: func.isRequired,
    isSuccess: bool.isRequired,
    isLoading: bool.isRequired,
    response: oneOfType([string, shape({})])
  }).isRequired,
  getProfilesForOrganisation: shape({
    fetch: func.isRequired,
    response: shape({}).isRequired,
    isSuccess: bool.isRequired
  }).isRequired,
  sideEffect: shape({
    submit: func.isRequired
  }).isRequired
};

GatewayComp.defaultProps = {};

const _Gateway = compose(
  withHandleWorkspacesStatus({ updateStatusFromApi: true }),
  withDataSubmission({ propNameWrapper: 'activateOrganisation' }),
  withDataFetching({ propNameWrapper: 'getProfilesForOrganisation' }),
  withWorkspacesOrganisations({ propNameWrapper: 'getOrganisation' }),
  withDataSubmission({ propNameWrapper: 'sideEffect' })
)(GatewayComp);

export const Gateway = ({ match }) => {
  if (isToggleEnabled('neon')) {
    const workspaceId = parseInt(match.params.organisationId, 10);

    return (<ModularPage
      pageContent={<GatewayContent_NEON workspaceId={workspaceId} />}
      navigationContent={<MemberAppNavigation_Populated_NEON currentPage="workspace" />}
    />);
  }

  return <ModularPage
    pageContent={<_Gateway match={match} />}
    navigationContent={<MemberAppNavigation_Populated currentPage="workspace" />}
  />;
};

Gateway.propTypes = {
  match: shape({
    params: shape({
      organisationId: string.isRequired
    }).isRequired
  }).isRequired
};
