import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import {
  Carousel,
  CountableSectionHeader,
  ExperienceCardSkeleton,
  FeedFooter,
  InnerContentWrapper,
  MainContentWrapper,
  PageColumn
} from '@paddl/storybook';
import {
  AllProgramsSection,
  Metadata,
  LiveExperienceSummaryCard,
  OnDemandExperienceSummaryCard
} from '@paddl/utils-react';
import * as Sentry from '@sentry/browser';
import {
  arrayOf,
  bool,
  number,
  shape,
  string
} from 'prop-types';
import React, { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { DateTime } from 'luxon';
import { hardRedirect } from '@paddl/utils-js';

const renderSessionCardSkeletons = () =>
  [1, 2, 3].map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Grid item xs={12} md={4} lg={4} key={`$session-card-${index}`}>
        <ExperienceCardSkeleton imageHeight={140} />
      </Grid>
  ));

const ErrorFallback = () => { return <Alert severity="error">Error rendering carousel</Alert>; };

export const WorkspacePageContent = ({
  organisationId,
  programs,
  isLiveExperienceDataLoading,
  upNextExperiences,
  isOnDemandExperienceDataLoading,
  recommendedExperiences,
  pageTitle
}) => {
  const onError = (message, error, info) => {
    console.error(message);
    Sentry.addBreadcrumb(message);
    Sentry.addBreadcrumb(info);
    Sentry.captureException(error);
  };

  const upNextSessionItems = useMemo(() => {
    return upNextExperiences
      .map(({
        id, title, liveEvent, activityType, programId
      }) => {
        return (
          <LiveExperienceSummaryCard
            id={id}
            data-test-id={`experince-card-${id}`}
            key={id}
            title={title}
            dateTimeFormatted={liveEvent ? DateTime.fromISO(liveEvent.dateTime).toFormat('dd MMM • h:mma') : ''}
            handleSeeMore={() => hardRedirect('workspaceProgramExperience', { organisationId, programId, experienceId: id })}
            hostId={liveEvent?.hostId}
            isLiveEvent={!!liveEvent}
            activityType={activityType}
          />);
      });
  }, [organisationId, upNextExperiences]);

  const recommendedExperienceItems = useMemo(() => {
    return recommendedExperiences
      .map(({
        id, title, activityType, providerId, duration, programId
      }) => {
        return (
          <OnDemandExperienceSummaryCard
            id={id}
            data-test-id={`experince-card-${id}`}
            key={id}
            title={title}
            handleSeeMore={() => hardRedirect('workspaceProgramExperience', { organisationId, programId, experienceId: id })}
            providerId={providerId}
            duration={duration}
            activityType={activityType}
          />);
      });
  }, [organisationId, recommendedExperiences]);

  return <MainContentWrapper colorVariant="dark" withGhostNav>
    <Metadata title={`${pageTitle} | Paddl`} />
      <InnerContentWrapper size="large">
        <PageColumn>
          {(isLiveExperienceDataLoading || upNextExperiences.length > 0) &&
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error, info) => onError("Error rendering 'Coming Up' carousel", error, info)}>
            <Carousel
              header={
                <CountableSectionHeader sx={{ flex: 1, margin: 0 }} title="Coming Up" itemCount={upNextExperiences.length} isLoading={isLiveExperienceDataLoading} />
              }
              items={isLiveExperienceDataLoading ? renderSessionCardSkeletons() : upNextSessionItems}
            />
            </ErrorBoundary>}
            {(isOnDemandExperienceDataLoading || recommendedExperienceItems.length > 0) &&
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error, info) => onError("Error rendering 'New from your programs' carousel", error, info)}>
             <Carousel
               header={
                <CountableSectionHeader sx={{ flex: 1, margin: 0 }} isLoading={isOnDemandExperienceDataLoading} title="New from your programs" itemCount={recommendedExperienceItems.length} />
              }
               items={isOnDemandExperienceDataLoading ? renderSessionCardSkeletons() : recommendedExperienceItems}
             />
            </ErrorBoundary>}

            <AllProgramsSection programs={programs || []} isLoading={!programs} />
            <FeedFooter />
        </PageColumn>
      </InnerContentWrapper>
         </MainContentWrapper>;
};

WorkspacePageContent.propTypes = {
  programs: arrayOf(shape({})),
  isLiveExperienceDataLoading: bool,
  upNextExperiences: arrayOf(shape({})).isRequired,
  isOnDemandExperienceDataLoading: bool,
  recommendedExperiences: arrayOf(shape({})).isRequired,
  pageTitle: string.isRequired,
  organisationId: number.isRequired

};
WorkspacePageContent.defaultProps = {
  programs: null,
  isLiveExperienceDataLoading: false,
  isOnDemandExperienceDataLoading: false

};
