import React, { useState, useEffect } from 'react';
import { number, string, oneOfType } from 'prop-types';
import { Leaderboard } from '@paddl/storybook';
import { hardRedirect } from '@paddl/utils-js';
import { useFetchData } from '@paddl/utils-react';

const PROFILES_IN_SUMMARY = 5;
const TOTAL_PROFILES = 10;
const TEMP_USER_NAME = 'TEMP_USER_NAME';
export const ProfileTopListByXp = ({ organisationId }) => {
  const { fetchData } = useFetchData();
  const [profiles, setProfiles] = useState();

  useEffect(() => {
    const controller = new AbortController();
    fetchData(
      `${process.env.API_PREFIX_AT_WORK}/at-work/organisation/${organisationId}/leaderboard`
    ).then((leaderboardData) => {
      if (controller.signal.aborted) return;
      const rawProfiles = leaderboardData.profiles;
      const activeProfiles = rawProfiles?.filter(({ fullName }) => TEMP_USER_NAME !== fullName) || undefined;
      setProfiles(activeProfiles);
    }).catch((error) => {
      console.error(error);
    });

    return () => {
      controller.abort();
    };
  }, [fetchData, organisationId]);

  return (
    <Leaderboard
      data-test-id="team-leaderboard"
      onProfileClick={(profileId) => hardRedirect('profileById', { id: profileId })}
      profiles={profiles}
      profilesInSummary={PROFILES_IN_SUMMARY}
      totalProfiles={TOTAL_PROFILES}
    />
  );
};

ProfileTopListByXp.propTypes = {
  organisationId: oneOfType([
    string,
    number
  ]).isRequired
};
