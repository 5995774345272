import { CompanyBadgeCard, SidebarSection } from '@paddl/storybook';
import { useResource } from '@paddl/utils-react';
import {
  number
} from 'prop-types';
import React, { useState } from 'react';
import { WorkspaceDirectoryModal } from './WorkspaceDirectoryModal';
import { ProfileTopListByXp_NEON } from '../../components/ProfileTopListByXp/ProfileTopListByXp_NEON';

export const WorkspaceSidebar_NEON = ({
  workspaceId
}) => {
  const [isProfileDirectoryOpen, setIsProfileDirectoryOpen] = useState(false);

  const { data, isLoading } = useResource(`${process.env.AT_WORK_API}/v2/workspace/${workspaceId}/overview`);

  const {
    logoUrl, workspaceName, totalMembers, totalXp
  } = data?.workspaceOverview || {};
  if (isLoading) return null;

  return (
      <div>
      <SidebarSection>
        <CompanyBadgeCard
          logo={logoUrl || ''}
          name={workspaceName}
          numberOfProfiles={totalMembers}
          totalXp={totalXp}
          membersLinkOnClick={() => setIsProfileDirectoryOpen(true)}
        />
      </SidebarSection>
      <SidebarSection>
        <ProfileTopListByXp_NEON workspaceId={workspaceId} />
      </SidebarSection>
      {isProfileDirectoryOpen && <WorkspaceDirectoryModal
        isModalOpen={isProfileDirectoryOpen}
        workspaceId={workspaceId}
        onClose={() => setIsProfileDirectoryOpen(false)}
      />}
      </div>
  );
};

WorkspaceSidebar_NEON.propTypes = {
  workspaceId: number.isRequired
};
